import { render, staticRenderFns } from "./changeCurrencyModal.vue?vue&type=template&id=7a24b9dc&scoped=true&"
import script from "./changeCurrencyModal.vue?vue&type=script&lang=js&"
export * from "./changeCurrencyModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a24b9dc",
  null
  
)

export default component.exports